/**
 * @module Helpers
 * @description Some handy helpers
 */
const fastlyBase = process.env.imageDomain;

/**
 * @function generateIdNumber
 * @description Generate 20 digit random integer
 */
export const generateIdNumber = () => Math.round( Math.random() * 10000000 ).toString() + new Date().getTime();

/**
 * @function getAsset
 * @description Get asset from basePath
 */
export const getAsset = ( asset = '' ) => `${ asset }`;

/**
 * @function localImage
 * @description Our local image loader
 */
export const localImage = ( asset ) => getAsset( asset.src );

/**
 * @function fastly
 * @description Our local image loader
 */
export const fastly = ( asset ) => {
  let base = `${ fastlyBase }${ asset.src }`;
  if ( asset.width || asset.height ) {
    base += `?width=${ asset.width }&height=${ asset.height }`;
  }

  return base;
};

/**
 * @function fastlyBlur
 * @description Our fastly blur helper
 */
export const fastlyBlur = ( src ) => `${ fastlyBase }${ src }?width=10`;

export const getBaseAssetName = ( asset ) => {
  let finalName;
  if ( asset && asset.assetName ) {
    const { assetName = '' } = asset;
    finalName = assetName.includes( '@' ) ?
      assetName.split( '@' )[ 0 ].trimEnd() :
      assetName;
  }

  return finalName;
};

/**
 * @function isBeforeDate
 * @description Checks if second date is before the first date
 * @param {string} dateString1 - ISO date string (e.g. "2025-01-14T12:14:18.812Z")
 * @param {string} dateString2 - Date in MM-DD-YY format (e.g. "01-10-25")
 * @returns {boolean} - True if second date is before first date, false if both dates exist and second is not before first
 */
export const isBeforeDate = ( dateString1, dateString2 ) => {
  if ( ! dateString1 || ! dateString2 ) return true; // Return true if either date is undefined

  const date1 = new Date( dateString1 );

  const [ month, day, year ] = dateString2.split( '-' );
  const date2 = new Date( 2000 + parseInt( year, 10 ), parseInt( month, 10 ) - 1, parseInt( day, 10 ) );

  if ( Number.isNaN( date1.getTime() ) || Number.isNaN( date2.getTime() ) ) return true; // Return true if either date is invalid

  return date2 < date1;
};
