// Checks to see if the user has a Spanish language in their top 3 preferred languages or if they have the ljs=es-US query parameter in the URL
export function spanishPreferred() {
  if ( typeof window !== 'undefined' ) {
    const languagesArray = [ ...navigator?.languages ];

    const searchParams = new URLSearchParams( window.location?.search || '' );
    const ljs = searchParams.get( 'ljs' );

    const prefersSpanish = languagesArray.slice( 0, 3 ).some( ( language ) => language.includes( 'es' ) ) ||
    ljs === 'es-US';

    return prefersSpanish;
  }
}
