import { lazy } from 'react';

/**
 Maps the Monarch UI Component customId to the React component path in our repo
 * */
const listOfMonarchComponents = {
  infrBannerLtoBricksEditable: lazy( () => import( '@/monarch/Banner | LTO (Bricks) - Editable' ) ),
  infrHeroPricePointEditable: lazy( () => import( '@/monarch/Hero | Price Point - Editable' ) ),
  infrHeroEditable: lazy( () => import( '@/monarch/Hero - Editable' ) ),
  infrHeroBauBricksEditable: lazy( () => import( '@/monarch/Hero | BAU (Bricks) - Editable' ) ),
  infrHeroVerticalHeroBricksEditable: lazy( () => import( '@/monarch/Hero | Vertical Hero (Bricks) - Editable' ) ),
  infrModalReturnVisitorRefreshImage: lazy( () => import( '@/monarch/Modal | Return Visitor Refresh Image' ) ),
  infrModalReturnVisitorRefreshDualCta: lazy( () => import( '@/monarch/Modal | Return Visitor Refresh Dual CTA' ) ),
  infrSectionFaqBricks: lazy( () => import( '@/monarch/Section | FAQ (Bricks)' ) ),
  infrSectionGdtEditable: lazy( () => import( '@/monarch/Section | GDT - Editable' ) ),
  infrSectionCardsCarouselBricksEditable: lazy( () => import( '@/monarch/Section | Cards Carousel (Bricks) - Editable' ) ),
  infrSectionSpeedVisualsBricksEditable: lazy( () => import( '@/monarch/Section | Speed Visuals (Bricks) - Editable' ) ),
  infrSectionEmailCaptureEditable: lazy( () => import( '@/monarch/Section | Email Capture - Editable' ) ),
  infrSectionBadgeEditable: lazy( () => import( '@/monarch/Section | Badge - Editable' ) ),
  infrSectionColumnTilesEditable: lazy( () => import( '@/monarch/Section | Column Tiles - Editable' ) ),
  infrSectionColumnTilesBricksEditable: lazy( () => import( '@/monarch/Section | Column Tiles (Bricks) - Editable' ) ),
  infrSectionColumnTilesBricksReviews: lazy( () => import( '@/monarch/Section | Column Tiles (Bricks) | Reviews' ) ),
  infrSectionLargeBannerBricksEditable: lazy( () => import( '@/monarch/Section | Large Banner (Bricks) - Editable' ) ),
  infrSectionCabinetSingleEditable: lazy( () => import( '@/monarch/Section | Cabinet | Single - Editable' ) ),
  infrSectionSplitModuleBricksEditable: lazy( () => import( '@/monarch/Section | Split Module (Bricks) - Editable' ) ),
  infrSectionCarouselSlimEditable: lazy( () => import( '@/monarch/Section | Carousel | Slim - Editable' ) ),
  infrHeroCarouselRedesignEditable: lazy( () => import( '@/monarch/Hero | Carousel | Redesign - Editable' ) ),
  infrSectionProductCardsBricksEditable: lazy( () => import( '@/monarch/Section | Product Cards (Bricks) - Editable' ) ),
  infrBannerLtoEditable: lazy( () => import( '@/monarch/Banner | LTO - Editable' ) ),
  infrSectionColumnTilesCopper: lazy( () => import( '@/monarch/Section | Column Tiles | Copper' ) ),
  infrSectionsFiberFeaturesEero: lazy( () => import( '@/monarch/Sections | Fiber Features | Eero' ) ),
  infrSectionCabinet: lazy( () => import( '@/monarch/Section | Cabinet' ) ),
  infrSectionColumnContent5G: lazy( () => import( '@/monarch/Section | Column Content | 5G' ) ),
  infrHeroPricePointTiered5GigP72: lazy( () => import( '@/monarch/Hero | Price Point | Tiered - 5 Gig P72' ) ),
  infrHeroPricePointTiered5Gig: lazy( () => import( '@/monarch/Hero | Price Point | Tiered - 5 Gig' ) ),
  infrHeroAddressCheckYttv: lazy( () => import( '@/monarch/Hero | Address Check | YTTV' ) ),
  infrBannerAddressCheckSingleLine: lazy( () => import( '@/monarch/Banner | Address Check | Single Line' ) ),
  infrSectionSplitSectionExpansion: lazy( () => import( '@/monarch/Section | Split Section | Expansion' ) ),
  infrSectionPricePointHeadlineLeadBlueCta: lazy( () => import( '@/monarch/Section | Price Point | Headline Lead - Blue CTA' ) ),
  infrSectionSplitSectionGamers: lazy( () => import( '@/monarch/Section | Split Section | Gamers' ) ),
  infrSectionGdtCta: lazy( () => import( '@/monarch/Section | GDT | CTA' ) ),
  infrSectionCarouselMoversHeadlineThumbYttvSummerDeal: lazy( () => import( '@/monarch/Section | Carousel | Movers Headline Thumb - YTTV Summer Deal' ) ),
  infrSectionColumnsYttv: lazy( () => import( '@/monarch/Section | Columns | YTTV' ) ),
  infrHeroPricePointCopper: lazy( () => import( '@/monarch/Hero | Price Point | Copper' ) ),
  infrSectionColumnsFiberDark: lazy( () => import( '@/monarch/Section | Columns | Fiber Dark' ) ),
  infrSectionCarouselFiberMerchThumb: lazy( () => import( '@/monarch/Section | Carousel | Fiber Merch Thumb' ) ),
  infrSectionColumnsFiber: lazy( () => import( '@/monarch/Section | Columns | Fiber' ) ),
  infrSectionCarouselThumbCdapi: lazy( () => import( '@/monarch/Section | Carousel | Thumb CDAPI' ) ),
  infrSectionBannerYttv: lazy( () => import( '@/monarch/Section | Banner | YTTV' ) ),
  infrSectionSplitSectionEeroFrontier: lazy( () => import( '@/monarch/Section | Split Section | Eero + Frontier' ) ),
  infrHeroContentOnlyFutureIsFiberBlueCta: lazy( () => import( '@/monarch/Hero | Content Only | Future is Fiber - Blue CTA' ) ),
  infrSectionVasCardsEditable: lazy( () => import( '@/monarch/Section | VAS Cards - Editable' ) ),
  infrSectionBannerHeadingsEditable: lazy( () => import( '@/monarch/Section | Banner Headings - Editable' ) ),
  infrBannerCountdownEditable: lazy( () => import( '@/monarch/Banner | Countdown - Editable' ) ),
  infrSectionBentoBricksEditable: lazy( () => import( '@/monarch/Section | Bento (Bricks) - Editable' ) ),
  infrModalGdt: lazy( () => import( '@/monarch/Modal | GDT' ) ),
  infrSectionSplitSectionDoMoreBuiltForGaming: lazy( () => import( '@/monarch/Section | Split Section | Do More + Built for Gaming' ) ),
  infrSectionStickyCta: lazy( () => import( '@/monarch/Section | Sticky CTA' ) ),
  infrSectionSplitSectionFutureProofFiberBlueCta: lazy( () => import( '@/monarch/Section | Split Section | Future Proof Fiber - Blue CTA' ) ),
  infrBannerListExpansion: lazy( () => import( '@/monarch/Banner | List | Expansion' ) ),
  infrBannerCtaNeighborhood: lazy( () => import( '@/monarch/Banner | CTA | Neighborhood' ) ),
  infrSectionSplitSectionYttv: lazy( () => import( '@/monarch/Section | Split Section | YTTV' ) ),
  infrSectionGdtQuiz: lazy( () => import( '@/monarch/Section | GDT | QUIZ' ) ),
  infrSectionFaqMovers: lazy( () => import( '@/monarch/Section | FAQ | Movers' ) ),
  infrSectionColumnTilesFiber: lazy( () => import( '@/monarch/Section | Column Tiles | Fiber' ) ),
  infrSectionPricePoint5Gig: lazy( () => import( '@/monarch/Section | Price Point | 5 Gig' ) ),
  infrSectionStickyCtaGdt: lazy( () => import( '@/monarch/Section | Sticky CTA | GDT' ) ),
  infrSectionCarouselThumbExpansion: lazy( () => import( '@/monarch/Section | Carousel | Thumb Expansion' ) ),
  infrSectionCarouselP72ThumbUncableLanguage: lazy( () => import( '@/monarch/Section | Carousel | P72 Thumb Uncable Language' ) ),
  infrBannerAddressCheckWhite: lazy( () => import( '@/monarch/Banner | Address Check | White' ) ),
  infrBannerAddressCheckStickyWhite: lazy( () => import( '@/monarch/Banner | Address Check | White' ) ),
  infrHeroAddressCheck5G: lazy( () => import( '@/monarch/Hero | Address Check | 5G' ) ),
  infrSectionEmpty: lazy( () => import( '@/monarch/Section | Empty' ) ),
  infrHeroContentOnlyWiFi7: lazy( () => import( '@/monarch/Hero | Content Only | Wi-Fi 7' ) ),
  infrSectionFaqCopper: lazy( () => import( '@/monarch/Section | FAQ | Copper' ) ),
  infrSectionPricePointCopper: lazy( () => import( '@/monarch/Section | Price Point | Copper' ) ),
  infrHeroContentOnlyMovers: lazy( () => import( '@/monarch/Hero | Content Only | Movers' ) ),
  infrSectionColumnTilesMovers: lazy( () => import( '@/monarch/Section | Column Tiles | Movers' ) )
};

export default listOfMonarchComponents;
